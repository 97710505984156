body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

#root > div {
  background-image: none !important;
}

@media (max-width: 599.95px) {
  .actionButton {
    padding: 12px !important;
  }
}

@media (max-width: 959.95px) {
  .mobileList > div {
    width: 100%;
  }

  .mobileList > div > div > ul > a > div {
    padding-left: 0;
    padding-right: 0;
  }

  .usersMobileList > a > div > div > span > div {
    height: 32px;
  }
}

.fileInput {
  display: none;
}

.listTag {
  padding: 4px 5px;
  border-radius: 4px;
  color: white;
  font-size: 0.6rem;
  text-transform: uppercase;
  font-weight: 600;
}

.image-field {
  display: flex;
  padding: 1px;
  border: 1px solid #ccc;
  margin: 0;
  margin-right: 6px;
  max-height: 10rem;
  max-width: 12rem;
  min-height: 3rem;
  min-width: 4rem;
}

.images-list {
  display: flex;
  flex-wrap: wrap;
}
